import { ResponsiveContainer, AreaChart, Area, BarChart, Bar } from "recharts";

interface SystemData {
  time: string;
  traffic: number;
  capacity: number;
}

const generateJitteredData = () => {
  const data: SystemData[] = [];

  for (let i = 0; i < 48; i++) {
    const time = `${i % 24}:00`;
    const baseTraffic = i < 4 || i > 20 ? 0 : 100;
    const jitteredTraffic = baseTraffic + Math.random() * 20 - 5; // Add jitter to traffic

    // Calculate the capacity based on the relationship with traffic
    const newCapacity = Math.min(15, Math.max(0, jitteredTraffic / 15)); // Cap at [0, 15]

    data.push({ time, traffic: jitteredTraffic, capacity: newCapacity });
  }

  return data;
};

export const AutoscaleChart: React.FC = () => {
  const data = generateJitteredData();
  return (
    <div className="border-dashed border-b border-white/20 pb-2">
      <ResponsiveContainer width="100%" height={164}>
        <AreaChart margin={{ top: 16 }} data={data}>
          <defs>
            <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#EA2804" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#EA2804" stopOpacity={0} />
            </linearGradient>
          </defs>
          <Area
            type="linear"
            dataKey="traffic"
            isAnimationActive={false}
            name="Traffic"
            stroke="#EA2804"
            fill="url(#gradient)"
            fillOpacity={0.25}
          />
        </AreaChart>
      </ResponsiveContainer>
      <ResponsiveContainer width="100%" height={132}>
        <BarChart margin={{ left: 0 }} data={data}>
          <Bar
            type="linear"
            dataKey="capacity"
            name="Capacity"
            isAnimationActive={false}
            // fill="url(#gradient)"
            fill="#EA2804"
            // stroke=""
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
